import fr from 'date-fns/locale/fr'
import format from 'date-fns/format'
import i18next from 'i18next'
import { ErrorSubtitleField, ErrorType, SubtitleType } from '../Mesvideo/models/MesvideoModel'

export const CalculTimeEnd = (timeStart: number): number => {
    const timeStartSplit = convertSecondToHHMMSSOrHHMM(timeStart)!.split(':')
    const hours = timeStartSplit.length === 3 ? parseInt(timeStartSplit[0]) : 0
    const minutes = timeStartSplit.length === 3 ? parseInt(timeStartSplit[1]) : parseInt(timeStartSplit[0])
    const seconds = timeStartSplit.length === 3 ? parseInt(timeStartSplit[2]) : parseInt(timeStartSplit[1])
    const newHours = minutes >= 60 ? hours + 1 : hours
    const newMinutes = seconds + 2 >= 60 ? minutes + 1 : minutes
    const newSeconds = seconds + 2 >= 60 ? seconds + 2 - 60 : seconds + 2
    const newHoursString = newHours.toString().length === 1 ? '0' + newHours.toString() : newHours.toString()
    const newMinutesString = newMinutes.toString().length === 1 ? '0' + newMinutes.toString() : newMinutes.toString()
    const newSecondsString = newSeconds.toString().length === 1 ? '0' + newSeconds.toString() : newSeconds.toString()
    return TimeStringToNumber(
        newHours > 0 ? `${newHoursString}:${newMinutesString}:${newSecondsString}` : `${newMinutesString}:${newSecondsString}`
    )
}

export const TimeStringToNumber = (timeString: string): number => {
    const date = new Date('01/01/1970 ' + (timeString.split(':').length === 2 ? '00:' : '') + timeString)
    // console.log(date, "01/01/1970 00:" + timeString, date.getHours()*3600 + date.getMinutes()*60 + date.getSeconds())
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()
}

export const formatNumber = (n: number | string | null): string => {
    if (typeof n === 'number') {
        return `${Math.round(n)}`
    }
    const nAsNumber = Number(n)
    return new Intl.NumberFormat('fr', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(isNaN(nAsNumber) ? 0 : nAsNumber)
}

export const humanizeNumber = (n: number) => new Intl.NumberFormat('fr').format(n)

export const arrayUnique = (tab: string[]): string[] => {
    return tab.filter((v, i, a) => a.indexOf(v) === i)
}

export const extractFilename = (str?: string): string => {
    if (!str) return i18next.t('label.video-no-title')
    let fileWithExt = str.split(/(\\|\/)/g).pop() ?? ''
    let filename = fileWithExt.replace(/\.[^/.]+$/, '')
    return filename || 'Donnée bidon'
}

export const round5 = (n: number): number => {
    return Math.ceil(n / 5) * 5
}

/**
 * to avoid date mutation
 * @param d date to clone
 */
export const cloneDate = (d: Date): Date => new Date(d.getTime())

export const dateDifference = (d1: Date, d2: Date): number => {
    let date1 = getDate(d1)
    let date2 = getDate(d2)
    if (!isValidDate(date1) || !isValidDate(date2)) {
        return 0
    }
    if (date1 && date2) return date1.getTime() - date2.getTime()
    else return 0
}

export const dateMinusNow = (d: Date): number => {
    let d1 = new Date(d)
    if (!isValidDate(d1)) return 0
    let date = getDate(d)
    return date.getTime() - Date.now()
}

export const getDate = (d: Date) => {
    let date: Date | any
    if (typeof d === 'string') date = new Date(d)
    else date = d
    return date
}

export const isValidDate = (d: any) => !Boolean(d) || d !== 'Invalid Date'

export const datePendingPublicationFormat = (d: Date | any): string => {
    try {
        let d1 = getDate(d)
        if (!isValidDate(d1)) return 'Invalid date'
        return `Le ${format(d1, `dd MMM yyyy à HH:mm`, { locale: fr })}`
    } catch (e) {
        return 'Invalid date'
    }
}

export const getDateOrNow = (d: any): Date => {
    if (!d) return new Date()
    let _d = getDate(d)
    if (isValidDate(_d)) return _d
    return new Date()
}

export function formatMillierNumber(n: number) {
    return String(n).replace(/(.)(?=(\d{3})+$)/g, '$1 ')
}

export function convertSecondToHHMMSSOrHHMM(n: number) {
    if (isNaN(n)) return
    const milliseconds = n * 1000
    if (milliseconds > 3600000) return new Date(milliseconds).toISOString().slice(11, 19)
    else return new Date(milliseconds).toISOString().slice(14, 19)
}

export function convertToMilliseconds(timeStr: string) {
    const cleanedTimeStr = timeStr.replace(/,[0-9]+/, '')
    const timeParts = cleanedTimeStr.split(/[:,]/)
    const hours = parseInt(timeParts[0], 10)
    const minutes = parseInt(timeParts[1], 10)
    const seconds = parseInt(timeParts[2], 10)
    const total = hours * 3600000 + minutes * 60000 + seconds * 1000
    return total / 1000
}

export function convertToSeconds(n: any) {
    let sp = n.time.split(':')
    let val: number = 0
    if (sp.length === 3) {
        let [hours, minutes, seconds] = n.time.split(':')
        val = Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
    } else if (sp.length === 2) {
        let [minutes, seconds] = n.time.split(':')
        val = Number(minutes) * 60 + Number(seconds)
    }
    return val
}

export function isEqualArray(tableau1: any, tableau2: any) {
    if (tableau1.length !== tableau2.length) return false

    return tableau1.every((value: any, index: number) => value === tableau2[index])
}
export function calculateErrors(subtitleDataLists: SubtitleType[], isPlayerOnError: any, videoDuration: any) {
    let doublons: any = []
        const errors: ErrorSubtitleField[] = []

        subtitleDataLists.forEach((subtitle, index) => {
            const start = Number(subtitle.time.start)
            const end = Number(subtitle.time.end)
            let doublon = false
            const timeStartDoublon = Number(subtitle.time.start)
            const timeEndDoublon = Number(subtitle.time.end)

            for (const e of doublons) {
                if (
                    (Number(e.time.end) >= timeEndDoublon && Number(e.time.start) < timeEndDoublon) ||
                    (Number(e.time.end) > timeStartDoublon && Number(e.time.start) <= timeStartDoublon)
                ) {
                    doublon = true
                    break
                }
            }

            const warningBlock = {
                type: ErrorType.WARNING,
                message: "La période d'affichage dépasse la durée totale de la vidéo. Le sous-titre ne sera pas enregistré ni affiché."
            }

            const ErrorBlock = {
                type: ErrorType.ERROR,
                message: 'Un sous-titre existe déjà sur cette période.'
            }

            const newError = {
                index,
                error: doublon
                    ? ErrorBlock
                    : !isPlayerOnError && (start > videoDuration || end > videoDuration)
                    ? warningBlock
                    : undefined
            }

            errors.push(newError)
            doublons.push(subtitle)
        })

        return errors
}
