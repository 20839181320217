import { parseISO } from 'date-fns'
import { ISelectUsersOption } from '../../Components/SelectUsers'
import Http, { ApiRootType, PostParams, progressEvent } from '../../Http/Http'
import { checkEmail } from '../../Utils/Str'
import {
    addPlaylistVideResponse,
    Author,
    Catalog,
    CatalogResponse,
    GetCatalogExternalPlaylistResponse,
    GetEncodedStatus,
    IVideoPublish,
    Localisation,
    MyPlaylistFilter,
    MyPlaylistResponse,
    MyPlaylistUpdateData,
    Playlist,
    PlaylistPartial,
    PlaylistWithVideoData,
    RawVideo,
    RawVideoResponse,
    ReoderVideoResponse,
    ResMessage,
    Statut,
    Tag,
    TagResponse,
    Territory,
    Thematic,
    Video,
    VideoFilter,
    VideoResponse,
    VideoUpload
} from '../models/MesvideoModel'
import { threeDayMs } from '../../Utils/Util'
import { ILiveConfig } from '../../live/constant/liveType'

// Nous avons déplacé l'emplacement des models et des tools de ce fichier. L'exportation depuis ce fichier nous permet de ne rien toucher au import
export * from '../models/MesvideoModel'
export * from '../tools/common'

const http = new Http()

const raw2Video = (row: RawVideo): Video => {
    return {
        ...row,
        tags: decorateTagName(row.tags || []),
        createdAt: parseISO(row.createdAt),
        updatedAt: parseISO(row.updatedAt),
        broadcastStart: row.broadcastStart ? parseISO(row.broadcastStart) : null,
        broadcastEnd: row.broadcastEnd ? parseISO(row.broadcastEnd) : null
    }
}

let counter = 0
function generateUniqueId() {
    return `${Date.now()}-${counter++}`
}

export const decorateTagName = (tag: any[]): Tag[] => tag.map(el => ({ ...el, name: el.tag }))
export class MesvideoService {
    static SHARE_TOKEN = 'video_share'
    static ADD_VIDEO_TO_PLAYLIST_TOKEN = 'add_video_to_playlist'
    static REORDER_VIDEO_TOKEN = 'reorder_video'
    static GET_VIDEO_BY_PLAYLIST = 'get_video_by_playlist'
    static CANCEL_DOWNLOAD: Map<string, string> = new Map()

    uploadVideo = (item: VideoUpload, parameters?: PostParams): Promise<any> => {
        const body = new FormData()
        body.append('temporaryId', item.temporaryId || '')
        body.append('file', item.file)
        body.append('disponibility', `${item.disponibility}`)
        return http
            .post<any>('videos/uploadFile', body, { ...parameters, cancelToken: 'upload_video', timeout: threeDayMs })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    publishVideo = (item: IVideoPublish): Promise<IVideoPublish> => {
        return http
            .post<IVideoPublish>('videos/publish', item, { cancelToken: 'publish_video' })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    encodeVideo = (video: number): Promise<{ video: IVideoPublish }> => {
        return http
            .post<any>('videos/encode', { video }, { cancelToken: 'encode_video' })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    postThumbnailsStatique = (id: string, proxyUrl: string): Promise<string[]> => {
        return http
            .post<string[]>(`thumbnails/${id}/generate`, { id }, { proxyUrl: proxyUrl })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    postThumbnailsDynamique = (video: number): Promise<string[]> => {
        return http
            .post<any>(`thumbnails/generate`, { video })
            .then(response => response.data.thumbnail)
            .catch(error => Promise.reject(error))
    }

    uploadThumbnail = (videoId: string, file: File): Promise<{ thumbnail: string }> => {
        const body = new FormData()
        body.append('file', file)
        body.append('video', `${videoId}`)
        return http
            .post<any>(`thumbnails/upload`, body)
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    ping = (): Promise<ResMessage> => {
        return http
            .get<ResMessage>('ping')
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    getEncodedStatus = (id: string): Promise<GetEncodedStatus> => {
        return http
            .sendGet<GetEncodedStatus>('videos/encoded', { id })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    get = (filter?: VideoFilter): Promise<VideoResponse> => {
        return http
            .sendGet<RawVideoResponse>('videos/default', {
                ...filter,
                page: filter?.page ?? 1,
                order: 'broadcastAt:' + filter?.order ?? 'DESC'
            })
            .then(response => ({ ...response.data, data: response.data.data.map(raw2Video) }))
            .catch(error =>
                Promise.reject({
                    data: [],
                    metadata: {
                        page: 1,
                        results_per_page: 0,
                        total: 0
                    }
                })
            )
    }
    getDefault = (filter?: VideoFilter): Promise<VideoResponse> => {
        return http
            .sendGet<RawVideoResponse>('videos/default', {
                ...filter,
                page: filter?.page ?? 1,
                order: 'broadcastAt:' + filter?.order ?? 'DESC'
            })
            .then(response => ({ ...response.data, data: response.data.data.map(raw2Video) }))
            .catch(error =>
                Promise.reject({
                    data: [],
                    metadata: {
                        page: 1,
                        results_per_page: 0,
                        total: 0
                    }
                })
            )
    }
    getProgrammed = (filter?: VideoFilter): Promise<VideoResponse> => {
        return http
            .sendGet<RawVideoResponse>('videos/programmed', {
                ...filter,
                page: filter?.page ?? 1,
                order: 'broadcastAt:' + filter?.order ?? 'DESC'
            })
            .then(response => ({ ...response.data, data: response.data.data.map(raw2Video) }))
            .catch(error =>
                Promise.reject({
                    data: [],
                    metadata: {
                        page: 1,
                        results_per_page: 0,
                        total: 0
                    }
                })
            )
    }
    /**Prend list playlist */
    getPlaylists = (): Promise<PlaylistPartial[]> => {
        return http
            .sendGet<PlaylistPartial[]>('playlists')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return []
            })
    }
    /**Prend list playlist */
    getMyPlaylists = (filter: MyPlaylistFilter): Promise<MyPlaylistResponse> => {
        return http.sendGet<MyPlaylistResponse>('myplaylist', filter).then(response => {
            return response.data
        })
    }
    /**Prend list playlist par id*/
    getMyPlaylistById = (palylistId: PlaylistPartial['id']): Promise<Playlist> => {
        return http.sendGet<Playlist>('playlists/' + palylistId).then(response => {
            return response.data
        })
    }
    /**Ajouter Playlist */
    addMyPlaylists = (
        title: string,
        isFavorite: boolean,
        siteId: number | null,
        description?: string,
        thumbnail?: string,
        extraData?: Record<string, string>[]
    ) => {
        return http
            .post<ResMessage>('myplaylist', {
                title,
                isFavorite,
                siteId,
                description,
                thumbnail,
                extraData
            })
            .then(response => {
                return response.data
            })
            .catch(error => Promise.reject(error))
    }
    /**Modify MyPlaylist */
    updateMyPlaylist = (palylistId: PlaylistPartial['id'], updateData: MyPlaylistUpdateData) => {
        return http
            .put<ResMessage>('myplaylist/' + palylistId, updateData)
            .then(response => {
                return response.data
            })
            .catch(() => null)
    }
    /**Suppresion MyPlaylist */
    deleteMyPlaylist = (palylistId: PlaylistPartial['id']) => {
        return http
            .delete('myplaylist/' + palylistId)
            .then(response => {
                return response.data
            })
            .catch(() => null)
    }
    /** suppression d'un video */
    deletVideo = (id: string): Promise<ResMessage> => {
        return http
            .delete('videos/' + id)
            .then(respons => {
                return respons.data.data
            })
            .catch(() => {
                return null
            })
    }
    deleteMultiple = (ids: string[]) => {
        return http.post('videos/delete', ids)
    }
    /**prend video par id */
    getVideoById = (id: string): Promise<Video | null> => {
        return http
            .sendGet<RawVideo>(`videos/${id}`)
            .then(result => {
                return raw2Video(result.data)
            })
            .catch(err => {
                return null
            })
    }
    /**Prend video par playlist */
    getVideoByPlaylist = (playlist_id: PlaylistPartial['id']): Promise<PlaylistWithVideoData[]> => {
        return http
            .get<PlaylistWithVideoData[]>('playlists/getVideo/' + playlist_id, { cancelToken: MesvideoService.GET_VIDEO_BY_PLAYLIST })
            .then(response => {
                return response.data.sort((a, b) => a.order - b.order) // Mettre les vidéos dans la bonne ordre pour ne pas s'embêter plus tard
            })
            .catch(e => Promise.reject(e))
    }

    abortVideoByPlaylist = () => {
        http.abortById(MesvideoService.GET_VIDEO_BY_PLAYLIST)
    }

    /**Ajouter video playlist */
    addVideoToPlaylistById = (video_id: string, playlist_id: PlaylistPartial['id']): Promise<addPlaylistVideResponse | null> => {
        return http
            .post<addPlaylistVideResponse>(
                `playlists/${playlist_id}/${video_id}`,
                {},
                { cancelToken: MesvideoService.ADD_VIDEO_TO_PLAYLIST_TOKEN }
            )
            .then(response => {
                return response.data
            })
            .catch(error => Promise.reject(error))
    }

    abortAddVideoToPlaylist = () => {
        http.abortById(MesvideoService.ADD_VIDEO_TO_PLAYLIST_TOKEN)
    }

    putVideo = (id: string, data_to_send: any): Promise<Video> => {
        return http
            .put<RawVideo>('videos/' + id, data_to_send)
            .then(response => raw2Video(response.data))
            .catch(error => Promise.reject(error))
    }

    /** Ajout sous titre video */
    addVideoSubtitle = (video_id: string, sub_file: File): Promise<any> => {
        const body = new FormData()
        body.append('file', sub_file)
        return http
            .post<any>(`videos/${video_id}/subtitle`, body)
            .then(response => {
                return response.data
            })
            .catch(() => {
                return null
            })
    }

    removeVideoSubtitle = (videoId: string) => {
        return http.delete(`videos/${videoId}/subtitle`)
    }

    downloadVideoSubtitle = (videoId: string) => {
        return http.download(`videos/${videoId}/subtitle`, { downloadFilename: 'subtitle' })
    }

    downloadVideoImageMiniature = (thumbnails: string, videoId: string) => {
        thumbnails.replace(/-\d+x\d+\.jpg/, '-crop.jpg')
        const cropFromatThumbnails = thumbnails.replace(/-\d+x\d+\.jpg/, '-crop.jpg')
        return http.downloadImage(`${cropFromatThumbnails}`, { downloadFilename: `${videoId}` })
    }

    getSubtitle = (video_id: string): Promise<any> => {
        return http
            .get<any>(`videos/subtitle/${video_id}`)
            .then(response => {
                return response.data
            })
            .catch(() => {
                return null
            })
    }

    postSubtitle = (id: number, file: File): Promise<any> => {
        return http
            .post<any>('videos/subtitle/upload', { id, file })
            .then(response => response.data)
            .catch(error => {})
    }
    uploadSubtitle = (video_id: number, file: File): Promise<any> => {
        const data = new FormData()
        data.append('file', file)
        return http
            .post<any>(`videos/${video_id}/subtitle`, data)
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    massUpdate = (
        ids: string[],
        disponibility: number,
        broadcastStart: Date | null,
        broadcastEnd: Date | null,
        liveConfig?: ILiveConfig | null
    ): Promise<Video[]> => {
        return http
            .put<RawVideo[]>(`videos/`, {
                ids,
                disponibility,
                broadcastStart,
                broadcastEnd,
                liveConfig
            })
            .then(response => response.data.map(raw2Video))
    }
    // getUserPermission = (): Promise<Permissions>=>{
    //     return http
    //         .get<UserResponse>(`user`)
    //         .then(response =>{
    //             return response.data.permissions
    //         })
    //         .catch((err)=>{
    //             return err
    //         })
    // }
    /** recupération de la liste des catalogues */
    getCatalogue = (): Promise<Catalog[]> => {
        return http
            .get<CatalogResponse[]>('catalogs')
            .then(response => {
                return response.data.map(d => {
                    // console.log(response.data)
                    return {
                        ...d,
                        name: d.name,
                        createdAt: parseISO(d.createdAt),
                        updatedAt: parseISO(d.updatedAt)
                    }
                })
            })
            .catch(() => {
                return []
            })
    }
    getAllCatalogue = (): Promise<Catalog[]> => {
        return http
            .get<CatalogResponse[]>('allCatalogs')
            .then(response => {
                return response.data.map(d => {
                    return {
                        ...d,
                        name: d.name,
                        createdAt: parseISO(d.createdAt),
                        updatedAt: parseISO(d.updatedAt)
                    }
                })
            })
            .catch(() => {
                return []
            })
    }
    /** recupération de la liste des thématique */
    getThematique = (): Promise<Thematic[]> => {
        return http
            .get<Thematic[]>('thematics')
            .then(response => {
                return response.data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (b.name < a.name) {
                        return 1
                    }
                    return 0
                })
            })
            .catch(() => {
                return []
            })
    }
    /** recupération de la liste des subthématique */
    getSubthematiqFor = (id: number): Promise<Thematic[]> => {
        return http
            .get<{ subThematics: Thematic[] }>(`thematics/${id}`)
            .then(response => {
                return response.data.subThematics
            })
            .catch(() => {
                return []
            })
    }
    /**Recuperation lien de stream */
    getStreamLink = (video_id: string) => {
        return http.streamLink(video_id)
    }
    /** recupération de la liste des auteurs */
    getAuteur = (): Promise<Author[]> => {
        return http
            .get<Author[]>('authors')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return []
            })
    }
    /** recupération de la liste des auteurs reponse complet */
    getAuteurFull = (): Promise<Author[]> => {
        return http
            .get<Author[]>('authors')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return []
            })
    }
    /** recupération de la liste des statut */
    getStatut = (): Promise<Statut[]> => {
        return http
            .get<Statut[]>('statut')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return []
            })
    }
    /** recupération de la liste des Localisation */
    getLocalisation = (): Promise<Localisation[]> => {
        return http
            .get<Localisation[]>('localisation')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return []
            })
    }

    /** recupération de la liste des tags */
    getTags = (): Promise<Tag[]> => {
        return http.get<TagResponse[]>('tags').then(response => {
            // Backward compatibilty for igy response
            if (response.data[0]?.tag === undefined) return response.data
            return response.data.map(d => ({ ...d, name: d.tag as string }))
        })
    }

    /** recupération de la liste des territoires de diffusion */
    getTerritories = (): Promise<Territory[]> => {
        return http.get<Territory[]>('territories').then(response => {
            return response.data
        })
    }

    download = (item: Video, progress?: (value: number) => void) => {
        const cancelToken = generateUniqueId()
        MesvideoService.CANCEL_DOWNLOAD.set(item.id, cancelToken)
        return http
            .downloadWithHeaders(`videos/${item.id}/download`, ApiRootType.one, item.encryptToken, item.title, progress, { cancelToken })
            .catch(console.error)
    }

    downloadMany = (items: Video[]) => {
        return http
            .post(
                `billings/download`,
                items.map(i => i.id),
                { downloadFilename: `bills.zip` }
            )
            .catch(console.error)
    }

    share = (videoId: string, zoneId: string, responsive: boolean, recipients: ISelectUsersOption[]) => {
        let users = recipients
            .filter(t => t.group === 'users')
            .map(v => {
                return { id: v.id }
            })
        let roles = recipients
            .filter(t => t.group === 'roles')
            .map(v => {
                return { id: v.id }
            })
        let all = recipients.filter(t => t.group === 'all').length > 0
        if (all) {
            return http.post(
                `videos/share/all`,
                { id: videoId, zoneId: zoneId, responsive: responsive },
                { cancelToken: MesvideoService.SHARE_TOKEN }
            )
        } else {
            return http.post(
                `videos/share`,
                {
                    id: videoId,
                    zoneId: zoneId,
                    responsive: responsive,
                    to: {
                        roles: roles,
                        users: users,
                        externs: recipients
                            .filter(t => t.group === 'externs')
                            .map(t => {
                                return { email: t.email ?? '' }
                            })
                            .filter(e => checkEmail(e.email))
                    }
                },
                { cancelToken: MesvideoService.SHARE_TOKEN }
            )
        }
    }

    abortShare = () => {
        http.abortById(MesvideoService.SHARE_TOKEN)
    }

    addVideoToPlayLists = (videoId: string, playlistsIds: PlaylistPartial['id'][]) => {
        return http.post(`videos/${videoId}/playlists`, playlistsIds)
    }

    getPlaylistsByVideo = (videoId: string): Promise<PlaylistPartial[]> => {
        return http.get<PlaylistPartial[]>(`videos/${videoId}/playlists`).then(response => response.data)
    }
    deledVideoFromPlaylist = (videoId: string, playlistId: PlaylistPartial['id']) => {
        return http.post(`playlists/removeVideo/${playlistId}/${videoId}`, {})
    }
    addVideosToPlayLists = (videoIds: string[], playlistIds: PlaylistPartial['id'][]) => {
        const data: { videoId: string; playlistId: PlaylistPartial['id'] }[] = videoIds.flatMap(v => {
            return playlistIds.map(p => ({
                videoId: v,
                playlistId: p
            }))
        })
        return http.post('videos/playlists', data)
    }
    reorderVideoPlaylist = (plailist_id: PlaylistPartial['id'], video_order: string[]): Promise<ReoderVideoResponse[]> => {
        return http
            .post<any>(
                `playlists/reorderVideo/${plailist_id}`,
                { videoOrder: video_order },
                { cancelToken: MesvideoService.REORDER_VIDEO_TOKEN }
            )
            .then(res => {
                return res.data.map((d: ReoderVideoResponse) => ({
                    ...d,
                    videoId: d.videoId.toString()
                }))
            })
            .catch(e => Promise.reject(e))
    }

    abortReorderVideoPlaylist = () => {
        http.abortById(MesvideoService.REORDER_VIDEO_TOKEN)
    }

    replaceVideo = (videoId: string, file: File, onProgress?: (p: progressEvent) => void) => {
        const body = new FormData()
        body.append('file', file)
        return http
            .post<any>(`videos/updateVideoFile/${videoId}`, body, { cancelToken: 'upload_video', onUploadProgress: onProgress })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
    }

    getProxyUrl = (): Promise<string> => {
        return http
            .get<string>('proxyurl')
            .then(response => response.data)
            .catch(error => '')
    }

    getCatalogExternalPlaylist = (catalogId: number) => {
        return http
            .get<GetCatalogExternalPlaylistResponse>(`catalogs/${catalogId}/externPlaylists`)
            .then(resp => resp.data)
            .catch(error => Promise.reject(error))
    }

    static abortDownload(id: string) {
        const cancelToken = MesvideoService.CANCEL_DOWNLOAD.get(id)
        if (cancelToken) {
            http.abortById(cancelToken)
            MesvideoService.CANCEL_DOWNLOAD.delete(id)
        }
    }
}
