import { RootState } from '..'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubtitle, SubtitleType } from '../../Mesvideo/models/MesvideoModel'

const defaultData: SubtitleType = {
    text: '',
    time: {
        start: '0',
        end: '2'
    },
    id: 1
}
const defaultSubtitleContentData: ISubtitle = {
    data: [defaultData]
}
interface ListCompanyState {
    dataEdited: ISubtitle
}

const initialState: ListCompanyState = {
    dataEdited: defaultSubtitleContentData
}


export const subtitleSlice = createSlice({
    name: 'subtitle',
    initialState,
    reducers: {
        setSubtitleValue(state, action: PayloadAction<ISubtitle>) {
            return { ...state, dataEdited: action.payload }
        },
        setDataSubtitle(state, action: PayloadAction<{ index: number, newSubtitle: SubtitleType }>) {
            const { index, newSubtitle } = action.payload
            const indexValue = state.dataEdited.data.findIndex(subtitle => subtitle.id === index)
            if (indexValue !== -1) {
                return {
                    ...state,
                    dataEdited: {
                        ...state.dataEdited,
                        data: [
                            ...state.dataEdited.data.slice(0, indexValue),
                            { ...state.dataEdited.data[indexValue], ...newSubtitle },
                            ...state.dataEdited.data.slice(indexValue + 1)
                        ]
                    }
                }
            }
            return state
        },
        setDataSubtitleTimeStart(state, action: PayloadAction<{ index: number, newSubtitle: SubtitleType }>) {
            const { index, newSubtitle } = action.payload
            const indexValue = state.dataEdited.data.findIndex(subtitle => subtitle.id === index)
            if (indexValue !== -1) {
                const updateSort:SubtitleType[]  = [
                    ...state.dataEdited.data.slice(0, indexValue),
                    { ...state.dataEdited.data[indexValue], ...newSubtitle },
                    ...state.dataEdited.data.slice(indexValue + 1)
                ]
                updateSort.sort((a, b) => Number(a.time.start) - Number(b.time.start))
                return {
                    ...state,
                    dataEdited: {
                        ...state.dataEdited,
                        data: updateSort
                    }
                }
            }
            return state
        },
        setTypeSubtitle(state, action: PayloadAction<string>) {
            return { ...state, dataEdited: {...state.dataEdited, type: action.payload} }
        },
        setActiveSubtitle(state, action: PayloadAction<boolean>) {
            return { ...state, dataEdited: {...state.dataEdited, active: action.payload} }
        },
        setStatusSubtitle(state, action: PayloadAction<number>) {
            return { ...state, dataEdited: {...state.dataEdited, status: action.payload} }
        },
        setAddNewSubtitle(state, action: PayloadAction<SubtitleType>) {
            const newValue = [...state.dataEdited.data, action.payload]
            newValue.sort((a: SubtitleType, b: SubtitleType) => Number(a.time.start) - Number(b.time.start))
            return { ...state, dataEdited: {...state.dataEdited, data: newValue} }
        }
    }
})

export const { setDataSubtitle, setTypeSubtitle, setSubtitleValue, setActiveSubtitle, setStatusSubtitle, setAddNewSubtitle, setDataSubtitleTimeStart } = subtitleSlice.actions

export const dataEdited = (state: RootState) => state.subtitle.dataEdited


export default subtitleSlice.reducer
