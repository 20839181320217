import moment from 'moment'
import Http from '../../Http/Http'
import { CaptionAutoService, CaptionAutoServiceResponse, Company, ConsumptionCompany, ConsumptionCompanyChartResponse, DateRangesType } from '../ConsumptionModels/ConsumptionModels'

const http = new Http()

export class ConsumptionService {
    getCompanies() {
        try {
            return http.get<Company[]>('consumption/companies').then(response => response.data)
        } catch (error) {
            console.error(error)
        }
    }
    getConsumptionCompanyById(id: number) {
        try {
            return http.get<ConsumptionCompany[]>(`consumption/company/${id}`).then(response => response.data)
        } catch (error) {
            console.error(error)
        }
    }
    updateCaptionAutoService(id: number, service: string, captionAutoService: CaptionAutoService) {
        try {
            return http.put<CaptionAutoServiceResponse>(`consumption/company/${id}/service/${service}`, captionAutoService).then(response => response.data)
        } catch (error) {
            console.error(error)
        }
    }
    getConsumptionCompany(id: number, service: string, startDate: string, endDate: string) {
        try {
            return http.get<ConsumptionCompanyChartResponse>(`consumption/company/${id}/service/${service}/${startDate}/${endDate}`).then(response => response.data)
        } catch (error) {
            console.error(error)
        }
    }
    exportConsumptionCompany(service: string, format: string, dateRanges: DateRangesType) {
        return http.post(`consumption/export/service/${service}/format/${format}`, dateRanges, { downloadFilename: `${moment(new Date()).format('YYYYMMDD')}_${service}.${format}` })
        
    }
}
