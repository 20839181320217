import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import { cloneDate, dateDifference, isValidDate, round5 } from '../../Utils/Numbers'
import { Video } from '../services/MesvideoService'

export const isVideoFicheComplete = (video: Video): boolean =>
    Boolean(video.title) &&
    Boolean(video.catalog) &&
    Boolean(video.thematic) &&
    Boolean(video.description) &&
    Boolean(video.tags.length > 0) &&
    Boolean(video.subthematics.length > 0)
export const isVideoFicheIncomplete = (video: Video): boolean => !isVideoFicheComplete(video)

export const disableHoursMinutesItem = (d1: Date, hours: string, minutes: string, d2: Date): boolean => {
    let d = cloneDate(d1)
    d.setHours(parseInt(hours))
    d.setMinutes(parseInt(minutes))
    d.setSeconds(0)
    return d.getTime() < d2.getTime()
}

export const getDefaultDate = (_d: Date | null, withArround = true) => {
    if (!_d) {
        let dateWith8h00mn = new Date()
        let d2 = new Date()
        dateWith8h00mn.setHours(8)
        dateWith8h00mn.setMinutes(0)
        if (dateDifference(dateWith8h00mn, d2) > 0) {
            return withArround ? arroundDate(dateWith8h00mn) : dateWith8h00mn
        } else {
            return withArround ? arroundDate(d2) : d2
        }
    } else {
        let date = cloneDate(_d)
        let dateWith8h00mn = new Date()
        dateWith8h00mn.setHours(8)
        dateWith8h00mn.setMinutes(0)
        if (dateDifference(dateWith8h00mn, date) > 0) {
            return withArround ? arroundDate(dateWith8h00mn) : dateWith8h00mn
        } else {
            return withArround ? arroundDate(date) : date
        }
    }
}

export const getHydratedDate = (d: any | null) => {
    let _d = new Date(d)
    if (!d || !isValidDate(_d)) {
        return getDefaultDate(d)
    }
    return arroundDate(_d)
}

export const arroundDate = (_d: Date): Date => {
    let d = cloneDate(_d)
    let min = round5(d.getMinutes())
    if (min === 60) {
        d.setMinutes(0)
        return moment(d).add(1, 'h').toDate()
    }
    d.setMinutes(min)
    return d
}

export const handleTeenValue = (n: number): string => {
    if (n < 10) return `0${n}`
    return `${n}`
}

export const getBroadcastEndDate = (broadcastStart: Date | null, broadcastEnd: Date | null): Date | null => {
    if (broadcastStart && !broadcastEnd) return null
    if (!broadcastStart && broadcastEnd) return new Date(broadcastEnd)
    if (broadcastStart && broadcastEnd) {
        let bs = new Date(broadcastStart)
        let be = new Date(broadcastEnd)
        if (isValidDate(bs) && isValidDate(be)) {
            let bsTime = bs.getTime()
            let beTime = be.getTime()
            return bsTime > beTime ? null : be
        }
    }
    return null
}

export const videoURI = (video: Video) => video.videoPath

export const formatDateTime = (date: Date): string => {
    moment.locale(i18next.language)
    return `${moment(date).format('L')} ${moment(date).format('LT')}`
}

/**
 * => DD/MM/YYYY - HH:mm, ex: 06/10/2022 - 22:57
 */
export const formatDate24H = (date: string | Date): string => {
    return moment(date).format('DD/MM/YYYY - HH:mm')
}
