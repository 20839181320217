import { RootState } from '..'
import { deleteZone, editSite, getCatalogs, getPlaylists, getSites, getSyndication, getSyndicationByType, getSyndicationCatalogs, getSyndicationPlaylist, getThematics, updateSyndication } from '../../Actions/playerSettings'
import { SaveZone, SiteWithParams, ZonePlayers } from '../../Components/Players/Model/PlayersModel'
import { ESubscription, SyndicationCatalogResponse, SyndicationParamsResponse, SyndicationPlaylistResponse, SyndicationResponse, SyndicationUpdateResponse } from '../../Components/Players/VerticalContent/Model/VerticalContentModel'
import { Catalog, MyPlaylistResponse, Thematic } from '../../Mesvideo/models/MesvideoModel'
import { FetchStatusType } from '../storeType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const defautValueSite = {
    id: 0,
    hasPlaylists: false,
    mdtk: '',
    label: '',
    zones: [],
    settings: {
        themes: {
            light: {
                backgroundColor: '',
                primaryColor: '',
                secondaryColor: ''
            },
            dark: {
                backgroundColor: '',
                primaryColor: '',
                secondaryColor: ''
            }
        }
    }
}
interface SelectedElement{
    id: number | string
    type: string
    title: string
    society: string
    updateDate: string
    img: string,
    canUnsubscribe: boolean
}
export const defaultElement : SelectedElement = {
    id: 0,
    type: '',
    title: '',
    society: '',
    updateDate: '',
    img: '',
    canUnsubscribe: false
}

interface playersSate {
    sites: SiteWithParams[]
    site: SiteWithParams
    statusGetSites: FetchStatusType
    updateSite: SiteWithParams
    statusEdit: FetchStatusType
    zones: ZonePlayers[]
    statusDelete: FetchStatusType
    lightTheme: boolean
    darkTheme: boolean
    colorBg: string
    colorPrimary: string
    colorSecondary: string
    darkColorBg: string
    darkColorPrimary: string
    darkColorSecondary: string
    menuExpanded: boolean
    statusThematics: FetchStatusType
    thematics: Thematic[]
    catalogs: Catalog[]
    randomChecked: boolean
    zone: string
    rangeSearch: number
    playlist: string
    catalogsSelected: Catalog[]
    playlists: MyPlaylistResponse
    zonesEdited: ZonePlayers[]
    saveZone: SaveZone[]
    isEdit: boolean
    refetch: number
    afterEdit: number
    isDisabled: boolean
    expanded: boolean
    tabValue: number
    isSubscribe: boolean
    isSeeAllPlaylist: boolean
    isSeeAllCatalog: boolean
    listSyndicats: SyndicationResponse
    loadingSyndicat: FetchStatusType
    loadingByType: FetchStatusType
    listByType: SyndicationParamsResponse[]
    syndicationCatalogs: SyndicationCatalogResponse
    loadingCatalogs: FetchStatusType
    syndicationPlaylist: SyndicationPlaylistResponse
    selectedElement: SelectedElement | null
    selectedVideo: string
    loadingPlaylist: FetchStatusType
    loadingUpdate: FetchStatusType
    isDisabledPrev: boolean
    isDisabledNext: boolean
    test: SyndicationUpdateResponse
    subscription: ESubscription
    tabChoice: number
    clicAction: number
}

const initialState: playersSate = {
    sites: [],
    statusGetSites: FetchStatusType.IDLE,
    updateSite: defautValueSite,
    statusEdit: FetchStatusType.IDLE,
    loadingSyndicat: FetchStatusType.IDLE,
    loadingByType: FetchStatusType.IDLE,
    zones: [],
    statusDelete: FetchStatusType.IDLE,
    site: defautValueSite,
    lightTheme: false,
    darkTheme: true,
    colorBg: '#000000',
    colorPrimary: '#2F45B5',
    colorSecondary: '#FFFFFF',
    menuExpanded: true,
    darkColorBg: '#000000',
    darkColorPrimary: '#2F45B5',
    darkColorSecondary: '#FFFFFF',
    statusThematics: FetchStatusType.IDLE,
    thematics: [],
    catalogs: [],
    randomChecked: false,
    zone: 'last',
    rangeSearch: 0,
    playlist: '',
    catalogsSelected: [],
    playlists: {
        data: [],
        metadata: {
            page: 0,
            results_per_page: 0,
            total: 0
        }
    },
    zonesEdited: [],
    saveZone: [],
    isEdit: false,
    refetch: 0,
    afterEdit: 0,
    isDisabled: false,
    expanded: false,
    tabValue: 0,
    isSubscribe: false,
    isSeeAllPlaylist: false,
    isSeeAllCatalog: false,
    listSyndicats: {
        catalogs: [],
        playlists: []
    },
    listByType: [],
    syndicationCatalogs: {
        ownCatalogs: [],
        subscribedCatalogs: []
    },
    loadingCatalogs: FetchStatusType.IDLE,
    syndicationPlaylist: {
        ownPlaylists: [],
        subscribedPlaylists: []
    },
    selectedElement: null,
    selectedVideo: '',
    loadingPlaylist: FetchStatusType.IDLE,
    loadingUpdate: FetchStatusType.IDLE,
    isDisabledPrev: false,
    isDisabledNext: false,
    test: {
        id: 0,
        subscribedSites: [],
        thematic: {
            id: 1,
            name: ''
        },
        thumbnail: '',
        title: ''
    },
    subscription: ESubscription.Tous_contenu,
    tabChoice: 0,
    clicAction: 0
}

export const playersSlice = createSlice({
    name: 'players',
    initialState,
    reducers: {
        setZones(state, action: PayloadAction<ZonePlayers[]>) {
            return { ...state, zones: action.payload }
        },
        setRefetch(state, action: PayloadAction<number>) {
            return { ...state, refetch: action.payload }
        },
        setSite(state, action: PayloadAction<SiteWithParams>) {
            return { ...state, site: action.payload }
        },
        setLightTheme(state, action: PayloadAction<boolean>) {
            return { ...state, lightTheme: action.payload }
        },
        setDarkTheme(state, action: PayloadAction<boolean>) {
            return { ...state, darkTheme: action.payload }
        },
        setBgColor(state, action: PayloadAction<string>) {
            return { ...state, colorBg: action.payload }
        },
        setPrimaryColor(state, action: PayloadAction<string>) {
            return { ...state, colorPrimary: action.payload }
        },
        setSecondaryColor(state, action: PayloadAction<string>) {
            return { ...state, colorSecondary: action.payload }
        },
        setMenuExpanded(state, action: PayloadAction<boolean>) {
            return { ...state, menuExpanded: action.payload }
        },
        setDarkBgColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorBg: action.payload }
        },
        setDarkPrimaryColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorPrimary: action.payload }
        },
        setDarkSecondaryColor(state, action: PayloadAction<string>) {
            return { ...state, darkColorSecondary: action.payload }
        },
        setRandomChecked(state, action: PayloadAction<boolean>) {
            return { ...state, randomChecked: action.payload }
        },
        setModeZone(state, action: PayloadAction<string>) {
            return { ...state, zone: action.payload }
        },
        setRangeSearch(state, action: PayloadAction<number>) {
            return { ...state, rangeSearch: action.payload }
        },
        setPlaylist(state, action: PayloadAction<string>) {
            return { ...state, playlist: action.payload }
        },
        setCatalogsSelected(state, action: PayloadAction<Catalog[]>) {
            return { ...state, catalogsSelected: action.payload }
        },
        setDefaultZone(state, action: PayloadAction<ZonePlayers[]>) {
            return { ...state, zonesEdited: action.payload }
        },
        setZoneSettings(state, action: PayloadAction<{ zoneId: any; newZone: ZonePlayers }>) {
            const { zoneId, newZone } = action.payload
            const updatedZones = state.zonesEdited.map(zone => {
                if (zone.id === zoneId) {
                    return newZone
                }
                return zone
            })
            return { ...state, zonesEdited: updatedZones }
        },
        setSaveZone(state, action: PayloadAction<SaveZone[]>) {
            return { ...state, saveZone: action.payload }
        },
        setIsEdit(state, action: PayloadAction<boolean>) {
            return { ...state, isEdit: action.payload }
        },
        setIsDisabled(state, action: PayloadAction<boolean>) {
            return { ...state, isDisabled: action.payload }
        },
        setExpanded(state, action: PayloadAction<boolean>) {
            return { ...state, expanded: action.payload }
        },
        setTabValue(state, action: PayloadAction<number>) {
            return { ...state, tabValue: action.payload }
        },
        setIsSubscribe(state, action: PayloadAction<boolean>) {
            return { ...state, isSubscribe: action.payload }
        },
        setIsSeeAllPlaylist(state, action: PayloadAction<boolean>) {
            return { ...state, isSeeAllPlaylist: action.payload }
        },
        setIsSeeAllCatalog(state, action: PayloadAction<boolean>) {
            return { ...state, isSeeAllCatalog: action.payload }
        },
        setSelectedElement(state, action: PayloadAction<SelectedElement | null>) {
            return { ...state, selectedElement: action.payload }
        },
        setSelectedVideo(state, action: PayloadAction<string>) {
            return { ...state, selectedVideo: action.payload }
        },
        setIsDisabledPrev(state, action: PayloadAction<boolean>) {
            return { ...state, isDisabledPrev: action.payload }
        },
        setIsDisabledNext(state, action: PayloadAction<boolean>) {
            return { ...state, isDisabledNext: action.payload }
        },
        setSubscription(state, action: PayloadAction<ESubscription>){
            return{...state, subscription: action.payload }
        },
        setTabChoice(state, action: PayloadAction<number>){
            return{ ...state, tabChoice: action.payload }
        },
        setClicAction(state, action: PayloadAction<number>){
            return{ ...state, clicAction: action.payload }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSites.pending, state => {
                return { ...state, statusGetSites: FetchStatusType.LOADING }
            })
            .addCase(getSites.fulfilled, (state, action) => {
                return { ...state, statusGetSites: FetchStatusType.COMPLETED, sites: action.payload }
            })
            .addCase(getSites.rejected, state => {
                return { ...state, statusGetSites: FetchStatusType.FAILED }
            })
        builder
            .addCase(editSite.pending, state => {
                return { ...state, statusEdit: FetchStatusType.LOADING }
            })
            .addCase(editSite.fulfilled, (state, action) => {
                const siteModify = action.payload
                const updatedSites = state.sites.map(site => {
                    if (site.id === siteModify.id) {
                        return siteModify
                    }
                    return site
                })

                return {
                    ...state,
                    statusEdit: FetchStatusType.COMPLETED,
                    updateSite: action.payload,
                    site: action.payload,
                    sites: updatedSites,
                    afterEdit: 1
                }
            })
            .addCase(editSite.rejected, state => {
                return { ...state, statusEdit: FetchStatusType.FAILED }
            })
        builder
            .addCase(deleteZone.pending, state => {
                return { ...state, statusDelete: FetchStatusType.LOADING }
            })
            .addCase(deleteZone.fulfilled, (state, action) => {
                return {
                    ...state,
                    statusDelete: FetchStatusType.COMPLETED,
                    zones: action.payload,
                    
                    // A NE SURTOUT PAS SUPPRIMER - CONTENU VERTICAUX
                    
                    // site: {
                        //     ...state.site,
                        //     zones: action.payload.zones
                        // }
                        
                    // A NE SURTOUT PAS SUPPRIMER - CONTENU VERTICAUX
                }
            })
            .addCase(deleteZone.rejected, state => {
                return { ...state, statusDelete: FetchStatusType.FAILED }
            })
        builder
            .addCase(getThematics.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getThematics.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, thematics: action.payload }
            })
            .addCase(getThematics.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
        builder
            .addCase(getCatalogs.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getCatalogs.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, catalogs: action.payload }
            })
            .addCase(getCatalogs.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
        builder
            .addCase(getPlaylists.pending, state => {
                return { ...state, statusThematics: FetchStatusType.LOADING }
            })
            .addCase(getPlaylists.fulfilled, (state, action) => {
                return { ...state, statusThematics: FetchStatusType.COMPLETED, playlists: action.payload }
            })
            .addCase(getPlaylists.rejected, state => {
                return { ...state, statusThematics: FetchStatusType.FAILED }
            })
        builder
            .addCase(getSyndication.pending, state => {
                return { ...state, loadingSyndicat: FetchStatusType.LOADING }
            })
            .addCase(getSyndication.fulfilled, (state, action) => {
                return { ...state, loadingSyndicat: FetchStatusType.COMPLETED, listSyndicats: action.payload }
            })
            .addCase(getSyndication.rejected, state => {
                return { ...state, loadingSyndicat: FetchStatusType.FAILED }
            })
        builder
            .addCase(getSyndicationByType.pending, state => {
                return { ...state, loadingByType: FetchStatusType.LOADING }
            })
            .addCase(getSyndicationByType.fulfilled, (state, action) => {
                return { ...state, loadingByType: FetchStatusType.COMPLETED, listByType: action.payload }
            })
            .addCase(getSyndicationByType.rejected, state => {
                return { ...state, loadingByType: FetchStatusType.FAILED }
            })
        builder
            .addCase(getSyndicationCatalogs.pending, state => {
                return { ...state, loadingCatalogs: FetchStatusType.LOADING }
            })
            .addCase(getSyndicationCatalogs.fulfilled, (state, action) => {
                return { ...state, loadingCatalogs: FetchStatusType.COMPLETED, syndicationCatalogs: action.payload }
            })
            .addCase(getSyndicationCatalogs.rejected, state => {
                return { ...state, loadingCatalogs: FetchStatusType.FAILED }
            })
        builder
            .addCase(getSyndicationPlaylist.pending, state => {
                return { ...state, loadingPlaylist: FetchStatusType.LOADING }
            })
            .addCase(getSyndicationPlaylist.fulfilled, (state, action) => {
                return { ...state, loadingPlaylist: FetchStatusType.COMPLETED, syndicationPlaylist: action.payload }
            })
            .addCase(getSyndicationPlaylist.rejected, state => {
                return { ...state, loadingPlaylist: FetchStatusType.FAILED }
            })
        builder
            .addCase(updateSyndication.pending, state => {
                return { ...state, loadingUpdate: FetchStatusType.LOADING }
            })
            .addCase(updateSyndication.fulfilled, (state, action) => {
                const updatedPlaylists = state.listSyndicats.playlists.map(playlist => {
                    if (playlist.id === action.meta.arg.id && action.meta.arg.type === 'playlist') {
                        return {
                            ...playlist,
                            subscribedSites: action.payload.subscribedSites
                        }
                    }
                    return playlist
                })
                const updatedCatalogs = state.listSyndicats.catalogs.map(catalog => {
                    if (catalog.id === action.meta.arg.id && action.meta.arg.type === 'catalog') {
                        return {
                            ...catalog,
                            subscribedSites: action.payload.subscribedSites
                        }
                    }
                    return catalog
                })

                return {
                    ...state,
                    loadingUpdate: FetchStatusType.COMPLETED,
                    listSyndicats: {
                        catalogs: updatedCatalogs,
                        playlists: updatedPlaylists
                    }
                }
            })
            .addCase(updateSyndication.rejected, state => {
                return { ...state, loadingUpdate: FetchStatusType.FAILED }
            })
    }
})

export const {
    setZones,
    setSite,
    setDarkTheme,
    setLightTheme,
    setBgColor,
    setPrimaryColor,
    setDarkBgColor,
    setDarkPrimaryColor,
    setDarkSecondaryColor,
    setSecondaryColor,
    setRandomChecked,
    setMenuExpanded,
    setModeZone,
    setRangeSearch,
    setPlaylist,
    setCatalogsSelected,
    setDefaultZone,
    setZoneSettings,
    setSaveZone,
    setIsEdit,
    setIsDisabled,
    setExpanded,
    setTabValue,
    setIsSubscribe,
    setIsSeeAllPlaylist,
    setIsSeeAllCatalog,
    setSelectedElement,
    setSelectedVideo,
    setIsDisabledPrev,
    setIsDisabledNext,
    setSubscription,
    setTabChoice,
    setClicAction
} = playersSlice.actions

export const site = (state: RootState) => state.players.site

export const listSites = (state: RootState) => state.players.sites

export const siteUpdated = (state: RootState) => state.players.updateSite

export const listeZones = (state: RootState) => state.players.zones

export const lightTheme = (state: RootState) => state.players.lightTheme

export const darkTheme = (state: RootState) => state.players.darkTheme

export const colorBg = (state: RootState) => state.players.colorBg

export const colorPrimary = (state: RootState) => state.players.colorPrimary

export const colorSecondary = (state: RootState) => state.players.colorSecondary

export const darkColorBg = (state: RootState) => state.players.darkColorBg

export const darkColorPrimary = (state: RootState) => state.players.darkColorPrimary

export const darkColorSecondary = (state: RootState) => state.players.darkColorSecondary

export const menuExpanded = (state: RootState) => state.players.menuExpanded

export const thematicLists = (state: RootState) => state.players.thematics

export const catalogLists = (state: RootState) => state.players.catalogs

export const checkedRandom = (state: RootState) => state.players.randomChecked

export const zoneMode = (state: RootState) => state.players.zone

export const rangeSearch = (state: RootState) => state.players.rangeSearch

export const playlist = (state: RootState) => state.players.playlist

export const catalogsSelected = (state: RootState) => state.players.catalogsSelected

export const playlistLists = (state: RootState) => state.players.playlists

export const zonesEdited = (state: RootState) => state.players.zonesEdited

export const zoneSave = (state: RootState) => state.players.saveZone

export const getStatusSite = (state: RootState) => state.players.statusGetSites

export const isEdit = (state: RootState) => state.players.isEdit

export const isDisabledButton = (state: RootState) => state.players.isDisabled

export const statusEdit = (state: RootState) => state.players.statusEdit

export const refetch = (state: RootState) => state.players.refetch

export const afterEdit = (state: RootState) => state.players.afterEdit

export const expanded = (state: RootState) => state.players.expanded

export const tabValue = (state: RootState) => state.players.tabValue

export const isSubscribe = (state: RootState) => state.players.isSubscribe

export const isSeeAllPlaylist = (state: RootState) => state.players.isSeeAllPlaylist

export const isSeeAllCatalog = (state: RootState) => state.players.isSeeAllCatalog

export const syndicats = (state: RootState) => state.players.listSyndicats

export const syndicatsByType = (state: RootState) => state.players.listByType

export const syndicationCatalogs = (state: RootState) => state.players.syndicationCatalogs

export const syndicationPlaylists = (state: RootState) => state.players.syndicationPlaylist

export const elementSelected = (state: RootState) => state.players.selectedElement

export const selectVideo = (state: RootState) => state.players.selectedVideo

export const loadingVertical = (state: RootState) => state.players.loadingSyndicat

export const loadingVerticalByType = (state: RootState) => state.players.loadingByType

export const loadingCatalogs = (state: RootState) => state.players.loadingCatalogs

export const loadingPlaylist = (state: RootState) => state.players.loadingPlaylist

export const loadingUpdate = (state: RootState) => state.players.loadingUpdate

export const isDisabledPrev = (state: RootState) => state.players.isDisabledPrev

export const isDisabledNext = (state: RootState) => state.players.isDisabledNext

export const subscription = (state: RootState) => state.players.subscription

export const tabChoice = (state: RootState) => state.players.tabChoice

export const actionClic = (state: RootState) => state.players.clicAction

export default playersSlice.reducer
