import Http from '../../../../Http/Http'
import {
    SyndicationCatalogResponse,
    SyndicationParamsResponse,
    SyndicationPlaylistResponse,
    SyndicationResponse,
    SyndicationUpdateResponse
} from '../Model/VerticalContentModel'

const http = new Http()

export class VerticalContentService {
    getSyndication(): Promise<SyndicationResponse> {
        return http.get<SyndicationResponse>(`syndication/verticalContents`).then(response => response.data)
    }
    getSyndicationParams(id: string | number, type: string): Promise<SyndicationParamsResponse[]> {
        return http.get<SyndicationParamsResponse[]>(`syndication/verticalContents/${type}/${id}`).then(response => response.data)
    }
    getSyndicationCatalog(idSite: string): Promise<SyndicationCatalogResponse> {
        return http.get<SyndicationCatalogResponse>(`syndication/catalogs?site=${idSite}`).then(response => response.data)
    }
    getSyndicationPlaylist(idSite: string): Promise<SyndicationPlaylistResponse> {
        return http.get<SyndicationPlaylistResponse>(`syndication/playlists?site=${idSite}`).then(response => response.data)
    }
    updateSyndication(type: string, id: number | string, idSite: string, subscribed: boolean): Promise<SyndicationUpdateResponse> {
        const bodyParams = { site: idSite, subscribed: subscribed }
        return http.put<SyndicationUpdateResponse>(`syndication/${type}/${id}`, bodyParams).then(response => response.data)
    }
}
