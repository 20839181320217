import { RootState } from '..'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChapitrageVideoEvo } from '../../Mesvideo/models/MesvideoModel'

interface listChapters {
    chaptersEdit: ChapitrageVideoEvo[]
}

const initialState: listChapters = {
    chaptersEdit: []
}


export const chapterSlice = createSlice({
    name: 'chapter',
    initialState,
    reducers: {
        setChaptersValue(state, action: PayloadAction<ChapitrageVideoEvo[]>) {
            return { ...state, chaptersEdit: action.payload }
        },
        setEditChapter(state, action: PayloadAction<{ index: number, newChapter: ChapitrageVideoEvo }>) {
            const { index, newChapter } = action.payload
            const indexValue = state.chaptersEdit.findIndex(chapter => chapter.id === index)
            if (indexValue !== -1) {
                return {
                    ...state,
                    chaptersEdit: [
                        ...state.chaptersEdit.slice(0, indexValue),
                        { ...state.chaptersEdit[indexValue], ...newChapter },
                        ...state.chaptersEdit.slice(indexValue + 1)
                    ]
                }
            }
            return state
        }
    }
})

export const { setChaptersValue, setEditChapter } = chapterSlice.actions

export const chapters = (state: RootState) => state.chapter.chaptersEdit


export default chapterSlice.reducer
