import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConsumptionService } from '../Consumption/ConsumptionServices/ConsumptionServices'
import { CaptionAutoService, Company, DateRangesType } from '../Consumption/ConsumptionModels/ConsumptionModels'

interface Update {
    id: number
    service: string
    captionAutoService: CaptionAutoService
}

interface listByDate {
    id: number
    service: string
    startDate: string
    endDate: string
}

interface downloadType {
    service: string
    format: string
    dateRanges: DateRangesType
}

const consumptionService = new ConsumptionService()

export const getCompanies = createAsyncThunk<Company[], void>('consumption/listCompany', async () => {
    const companies = await consumptionService.getCompanies()
    return companies || []
})

export const getCompanyById = createAsyncThunk('consumption/company', async (id: number) => {
    const company = await consumptionService.getConsumptionCompanyById(id)
    return company || []
})

export const updateCompany = createAsyncThunk('consumption/update', async (params: Update) => {
    const company = await consumptionService.updateCaptionAutoService(params.id, params.service, params.captionAutoService)
    return company ? company : { service: '', data: { alerts: [], currentUsage: 0, limit: 0, options: {}, unit: '' } }
})

export const getCompanyByDate = createAsyncThunk('consumption/date', async (params: listByDate) => {
    const response = await consumptionService.getConsumptionCompany(params.id, params.service, params.startDate, params.endDate)
    return response
        ? response
        : [
              {
                  type: '',
                  data: {
                      headers: [
                          {
                              title: '',
                              unit: '',
                              aggregation: null
                          }
                      ],
                      rows: [
                          {
                              className: '',
                              values: []
                          }
                      ]
                  }
              },
              {
                  type: '',
                  data: [
                      {
                          name: '',
                          values: []
                      }
                  ]
              }
          ]
})
export const downloadConsumption = createAsyncThunk('consumption/download', async (params: downloadType) => {
    const company = await consumptionService.exportConsumptionCompany(params.service, params.format, params.dateRanges)
    return company
})
