import { isHasRoleAdops } from '../Hooks/useModeManagement'
import Http from './Http'

class SessionHandler {
    // Pour la base url classic
    static ACCESS_TOKEN_KEY = 'access_token'
    // Pour la nouvelle base url
    static ACCESS_TOKEN_KEY2 = 'access_token2'
    // Pour veriflix
    static ACCESS_TOKEN_KEY3 = 'access_token3'

    static HAVE_ROLE_ADOPS_BUT_NO_TOKEN = 'HAVE_ROLE_ADOPS_BUT_NO_TOKEN'

    static VERIFLIX_USER = 'uv'

    static isConnected() {
        const api = new Http()
        let token1 = localStorage.getItem(SessionHandler.ACCESS_TOKEN_KEY)
        let token2 = localStorage.getItem(SessionHandler.ACCESS_TOKEN_KEY2)

        // Pas besoin de tester le token3 (veriflix) car on ne peut pas l'avoir si on n'a pas token1
        if (!token1 && !token2) return Promise.reject(false)

        return new Promise((resolve, reject) => {
            api.getDeprecated('user')
                .then(response => {
                    if (isHasRoleAdops(response) && !token2) {
                        reject(SessionHandler.HAVE_ROLE_ADOPS_BUT_NO_TOKEN)
                    } else {
                        resolve(response)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    static saveAccessToken(token: string) {
        localStorage.setItem(SessionHandler.ACCESS_TOKEN_KEY, token)
    }

    static saveAccessToken2(token: string) {
        localStorage.setItem(SessionHandler.ACCESS_TOKEN_KEY2, token)
    }

    static saveAccessToken3(token: string) {
        localStorage.setItem(SessionHandler.ACCESS_TOKEN_KEY3, token)
    }

    static getAccessToken() {
        return localStorage.getItem(SessionHandler.ACCESS_TOKEN_KEY)
    }

    static getAccessToken2() {
        return localStorage.getItem(SessionHandler.ACCESS_TOKEN_KEY2)
    }

    static getAccessToken3() {
        return localStorage.getItem(SessionHandler.ACCESS_TOKEN_KEY3)
    }

    static removeToken(ingoredSession: string[] = []) {
        let keys = Object.keys(localStorage)
        keys.filter(k => !ingoredSession.includes(k)).forEach(key => {
            localStorage.removeItem(key)
        })
    }

    static startVeriflixSession() {
        localStorage.setItem(SessionHandler.VERIFLIX_USER, '1')
    }

    static stopVeriflixSession() {
        localStorage.removeItem(SessionHandler.VERIFLIX_USER)
    }

    static isVeriflixUser() {
        return localStorage.getItem(SessionHandler.VERIFLIX_USER) === '1'
    }
}

export default SessionHandler
