import Http from '../../../Http/Http'
import { RequestBodyPlayers, SiteWithParams } from '../Model/PlayersModel'

const http = new Http()

export class PlayersService {
    getSitesWithParams(format: string): Promise<SiteWithParams[]> {
        return http.get<SiteWithParams[]>(`dashboard/sites?format=${format}`).then(response => response.data)
    }

    editSite = (id: string, body: RequestBodyPlayers) => {
        return http
            .put<SiteWithParams>(`settings/site/${id}`, body)
            .then(response => {
                return response.data
            })
            .catch(() => null)
    }
    deleteZone = (id: string) => {
        return http.delete(`settings/site/zone/${id}`).then(response => response.data)
    }
}
