import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import reducers from './slice'
export const store = configureStore({
    reducer: reducers,
})
  
  //  Define  types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>