import React, { createContext } from 'react'
import { User } from './models/User'
import { Dashboard } from './models/Dashboard'

export enum EAccessType {
    USER = 'user',
    ROOT = 'root'
}

const AppContext = createContext({
    pageStatus: { code: 0, text: '' },
    /**
     * @param {{code, text}} prop
     */
    setPageStatus: (prop: { code: number; text: string }) => {}
})

const UserContext = createContext<{ user: User; setUser: (prop: User) => void }>({
    user: {
        name: '',
        email: '',
        firstname: '',
        accessType: EAccessType.USER,
        id: 0,
        role: null,
        permissions: null
    },
    setUser: prop => {}
})

const DashboardContext = createContext<{ dashboards: Dashboard[]; setDashboards: (prop: Dashboard[]) => void }>({
    dashboards: [],
    setDashboards: (prop: Dashboard[]) => {}
})

const HomepageContext = createContext<{ currentHP: number; setCurrentHP: (n: number) => void }>({
    currentHP: 0,
    setCurrentHP: (n: number) => {}
})

const useUser = () => {
    return React.useContext(UserContext)
}

export { AppContext, UserContext, DashboardContext, HomepageContext, useUser }
