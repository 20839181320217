import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Env } from './Http/Http'

const backendOptions = {
    loadPath: (lang, ns) => {
        const [lng] = lang[0].split('-')
        return `${Env.ApiTranslation}/${lng}/${ns}.json`
    }
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        fallbackLng: 'fr',
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: backendOptions
    })

export default i18n
