import { RootState } from '..'
import { Company, ConsumptionCompany, ConsumptionCompanyChartResponse } from '../../Consumption/ConsumptionModels/ConsumptionModels'
import { downloadConsumption, getCompanies, getCompanyByDate, getCompanyById, updateCompany } from '../../Actions/consumption'
import { FetchStatusType } from '../storeType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Catalog } from '../../Mesvideo/models/MesvideoModel'

interface ListCompanyState {
    lists: Company[]
    statusGetCompany: FetchStatusType
    statusGetById: FetchStatusType
    statusUpdate: FetchStatusType
    statusTable: FetchStatusType
    statusDownload: FetchStatusType
    dataCompany: ConsumptionCompany[]
    dataTableDate: ConsumptionCompanyChartResponse
    company: ConsumptionCompany
    companyId: number
    activeStep: number
    isEdit: boolean
    catalogs: Catalog[]
}

const initialState: ListCompanyState = {
    lists: [],
    isEdit: false,
    statusGetCompany: FetchStatusType.IDLE,
    statusGetById: FetchStatusType.IDLE,
    statusUpdate: FetchStatusType.IDLE,
    statusTable: FetchStatusType.IDLE,
    statusDownload: FetchStatusType.IDLE,
    companyId: 0,
    dataCompany: [],
    activeStep: 0,
    company: {
        service: '',
        data: { alerts: [], currentUsage: 0, limit: 0, options: {}, unit: '' }
    },
    dataTableDate: [
        {
            type: '',
            data: {
                headers: [
                    {
                        title: '',
                        unit: '',
                        aggregation: null
                    }
                ],
                rows: [
                    {
                        className: '',
                        values: []
                    }
                ]
            }
        },
        {
            type: '',
            data: [
                {
                    name: '',
                    values: []
                }
            ]
        }
    ],
    catalogs: []
}

const updateService = (list: ConsumptionCompany[], update: ConsumptionCompany) => {
    return list.map(item => {
        return item.service === update.service ? {...item, ...update} : item;
    });
}


export const consumptionSlice = createSlice({
    name: 'consumption',
    initialState,
    reducers: {
        setCompany(state, action: PayloadAction<ConsumptionCompany>) {
            return { ...state, company: action.payload }
        },
        setCompanyId(state, action: PayloadAction<number>) {
            return {...state, companyId: action.payload}
        },
        setStatusUpdate(state, action: PayloadAction<FetchStatusType>) {
            return {...state, statusUpdate: action.payload}
        },
        setValueActiveStep(state, action: PayloadAction<number>) {
            return {...state, activeStep: action.payload}
        },
        setValueIsEdit(state, action: PayloadAction<boolean>) {
            return {...state, isEdit: action.payload}
        },
        setCatalogueList(state, action: PayloadAction<Catalog[]>) {
            return {...state, catalogs: action.payload}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCompanies.pending, state => {
                return { ...state, statusGetCompany: FetchStatusType.LOADING }
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                return { ...state, statusGetCompany: FetchStatusType.COMPLETED, lists: action.payload }
            })
            .addCase(getCompanies.rejected, state => {
                return { ...state, statusGetCompany: FetchStatusType.FAILED }
            })

        builder
            .addCase(getCompanyById.pending, state => {
                return { ...state, statusGetById: FetchStatusType.LOADING }
            })
            .addCase(getCompanyById.fulfilled, (state, action) => {
                return { ...state, statusGetById: FetchStatusType.COMPLETED, dataCompany: action.payload }
            })
            .addCase(getCompanyById.rejected, state => {
                return { ...state, statusGetById: FetchStatusType.FAILED }
            })

        builder
            .addCase(updateCompany.pending, state => {
                return { ...state, statusUpdate: FetchStatusType.LOADING }
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                return { ...state, statusUpdate: FetchStatusType.COMPLETED, dataCompany: updateService(state.dataCompany, action.payload) }
            })
            .addCase(updateCompany.rejected, state => {
                return { ...state, statusUpdate: FetchStatusType.FAILED }
            })
        builder
            .addCase(getCompanyByDate.pending, state => {
                return { ...state, statusTable: FetchStatusType.LOADING }
            })
            .addCase(getCompanyByDate.fulfilled, (state, action) => {
                return { ...state, statusTable: FetchStatusType.COMPLETED, dataTableDate: action.payload as ConsumptionCompanyChartResponse  }
            })
            .addCase(getCompanyByDate.rejected, state => {
                return { ...state, statusTable: FetchStatusType.FAILED }
            })
            
        builder
            .addCase(downloadConsumption.pending, state => {
                return { ...state, statusDownload: FetchStatusType.LOADING }
            })
            .addCase(downloadConsumption.fulfilled, state => {
                return { ...state, statusDownload: FetchStatusType.COMPLETED}
            })
            .addCase(downloadConsumption.rejected, state => {
                return { ...state, statusDownload: FetchStatusType.FAILED }
            })
    }
})

export const { setCompany, setCompanyId, setStatusUpdate, setValueActiveStep, setValueIsEdit, setCatalogueList } = consumptionSlice.actions

export const getIdCompany = (state: RootState) => state.consumption.companyId

export const getActiveStep = (state: RootState) => state.consumption.activeStep

export const getSingleCompany = (state: RootState) => state.consumption.company

export const getListCompany = (state: RootState) => state.consumption.lists

export const getCompany = (state: RootState) => state.consumption.dataCompany

export const getDataByDate = (state: RootState) => state.consumption.dataTableDate

export const getStatusCompany = (state: RootState) => state.consumption.statusGetCompany

export const getStatusById = (state: RootState) => state.consumption.statusGetById

export const getStatusUpdate = (state: RootState) => state.consumption.statusUpdate

export const getStatusTable = (state: RootState) => state.consumption.statusTable

export const getStatusDownload = (state: RootState) => state.consumption.statusDownload

export const getIsEdit = (state: RootState) => state.consumption.isEdit

export const catalogList = (state: RootState) => state.consumption.catalogs

export default consumptionSlice.reducer
