import { Component, ReactNode } from 'react'
import { FallBackLoading } from './FallabackLoading'

export class MainDigitekaErrorBoundary extends Component {
    state: {
        hasError: boolean
    }

    constructor(props: { children: ReactNode }) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        // TODO: send log to backend
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <FallBackLoading errorMessage="Slow connection" showReload />
        }

        return this.props.children
    }
}
