import { RootState } from '..'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DownloadLists } from '../../Mesvideo/models/MesvideoModel'

interface downloadState {
    listDownload: DownloadLists[]
}

const initialState: downloadState = {
    listDownload: []
}


export const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
        setListsDownload(state, action: PayloadAction<DownloadLists[]>) {
            return { ...state, listDownload: action.payload }
        },
        updateDownloadProgress(state, action: PayloadAction<{ downloadId: string; loader: number }>) {
            const { downloadId, loader } = action.payload
            const updatedLists = state.listDownload.map(download => {
                if (download.downloadId === downloadId) {
                    return { ...download, loader }
                }
                return download
            })
            return { ...state, listDownload: updatedLists }
        }
    }
})

export const { setListsDownload, updateDownloadProgress } = downloadSlice.actions

export const listsDownload = (state: RootState) => state.download.listDownload

export default downloadSlice.reducer
