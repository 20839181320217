import { Playlist } from "../../../../Mesvideo/models/MesvideoModel"

export interface SyndicationResponse {
    catalogs: CatalogPlaylist[]
    playlists: CatalogPlaylist[]
}
export interface CatalogPlaylist{
    id: number
    title: string
    companyName: string
    updateDate: string
    thumbnail: string
    subscribedSites: SubscribedSites[]
    thematic: Thematic
}
interface Thematic{
    id: number
    name: string
}
export interface SubscribedSites{
    id: string
    canUnsubscribe: boolean
}

export interface SyndicationParamsResponse {
    id: string
    title: string
    thumbnail: string
    mp4: string
}

export interface SyndicationCatalogResponse {
    subscribedCatalogs: SubscribedCatalog[]
    ownCatalogs: SubscribedCatalog[]
}
interface SubscribedCatalog{
    id: number
    createdAt: string
    updatedAt: string
    name: string
    lang: string
    subtitle: Subtitle
    thematic: Thematic
}
interface Subtitle{
    auto: boolean
    active: boolean
    blockAuto: boolean
}
export interface SyndicationPlaylistResponse {
    subscribedPlaylists: Playlist[]
    ownPlaylists: Playlist[]
}
 
 export interface SyndicationUpdateResponse{
    id: number
    title: string
    thumbnail: string
    subscribedSites: SubscribedSites[]
    thematic: Thematic
 }

 export enum ESubscription{
    Tous_contenu = 0,
    Contenu_abonne = 1
}